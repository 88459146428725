"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
const lower_bound_1 = require("./lower-bound");
class PriorityQueue {
  constructor() {
    this._queue = [];
  }
  enqueue(run, options) {
    options = Object.assign({
      priority: 0
    }, options);
    const element = {
      priority: options.priority,
      run
    };
    if (this.size && this._queue[this.size - 1].priority >= options.priority) {
      this._queue.push(element);
      return;
    }
    const index = lower_bound_1.default(this._queue, element, (a, b) => b.priority - a.priority);
    this._queue.splice(index, 0, element);
  }
  dequeue() {
    const item = this._queue.shift();
    return item === null || item === void 0 ? void 0 : item.run;
  }
  filter(options) {
    return this._queue.filter(element => element.priority === options.priority).map(element => element.run);
  }
  get size() {
    return this._queue.length;
  }
}
exports.default = PriorityQueue;