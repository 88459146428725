'use strict';

module.exports = (promise, onFinally) => {
  onFinally = onFinally || (() => {});
  return promise.then(val => new Promise(resolve => {
    resolve(onFinally());
  }).then(() => val), err => new Promise(resolve => {
    resolve(onFinally());
  }).then(() => {
    throw err;
  }));
};